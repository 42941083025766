export const AppConstants = {
  LABEL_SAVE: 'Save',
  LABEL_SUBMIT: 'Submit',
  LABEL_CANCEL: 'Cancel',
  LABEL_DELETE: 'Delete',
  LABEL_ADD: 'Add',
  LABEL_BACK: 'Back',
  LABEL_CONTINUE: 'Continue',
  LABEL_CLOSE: 'Close',
  LABEL_UPDATE: 'Update',

  // label for Student
  LABEL_ADD_STUDENT: 'Add Student',
  LABEL_SELECT_STUDENTS: 'Select Student',
  LABEL_DELETE_STUDENT: 'Delete Student',

  // route for Student
  ROUTE_STUDENTS: '/students/',
};

import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ApiEndpointsService } from '@core/services/api-endpoints.service';
import { ApiHttpService } from '@core/services/api-http.service';
import { VideoContent } from '@features/interactive-video/interactive-video.model';
import {
  QuestionContent,
  QuestionContentResponse,
} from '@features/quiz/models/question.model';
import { ReactionStat } from '@libs/reaction-stats/reactions-stats.model';
import { QueryStringParameters } from '@shared/classes/query-string-parameters';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AttendanceContent } from './attendance/attendance.model';
import {
  ClassSectionName,
  CourseEnrollment,
  SubGroupsResponse,
} from './dashboard.model';
import { DashboardService } from './dashboard.service';
import {
  Announcment,
  ClassListItem,
  CONTENT_TYPES,
  SubListItem,
} from './dashboard.model';
import {
  DocumentContentResponse,
  Question,
  QuestionResponse,
  DashboardContent,
  ChapterContent,
  ChapterView,
  CourseEnrollments,
  classCodeResponse,
  courseResponse,
} from './dashboard.model';
import {
  QuestionPaper,
  QuestionPaperReq,
} from './question-papers/question-paper-model';
import { LessonPlan } from './lesson-plans/lesson-plans.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardDataService {
  constructor(
    private apiEndpointService: ApiEndpointsService,
    private apiService: ApiHttpService,
    private dashboardService: DashboardService,
    public datepipe: DatePipe
  ) {}

  // TODO remove methods that return a promise instead of obserable

  getUserSubjectsByUserId(teacherMode: boolean = false) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariablesAndQueryParams(
        `enrollments`,
        [''],
        (qs: QueryStringParameters) => {
          qs.push(
            'date',
            `${this.datepipe.transform(new Date(), 'yyyy-MM-dd')}`
          );
          if (teacherMode) {
            qs.push('teacherAccount', true);
          }
        }
      );

    return this.apiService.getX<DashboardContent>(endpoint).pipe(
      map(({ error, loading, data }) => {
        if (!data) return { error, loading, data: null };
        if (data) return { error, loading, data };
      })
    );
  }

  getDataInsights(courseId: number, subjectId: number, date: string) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariablesAndQueryParams(
        'courses',
        [`${courseId}`, 'subjects', `${subjectId}`, 'insights'],
        (qs: QueryStringParameters) => {
          qs.push('date', `${date}`);
        }
      );

    return this.apiService.getX<any>(endpoint).pipe(
      map(({ error, loading, data }) => {
        if (!data) return { error, loading, data: null };
        if (data) return { error, loading, data };
      })
    );
  }

  getSections() {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariables('sections');

    return this.apiService.getX<any>(endpoint).pipe(
      map(({ error, loading, data }) => {
        if (!data) return { error, loading, data: null };
        if (data) return { error, loading, data };
      })
    );
  }

  getBoards() {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariables('syllabuses');

    return this.apiService.getX<any>(endpoint).pipe(
      map(({ error, loading, data }) => {
        if (!data) return { error, loading, data: null };
        if (data) return { error, loading, data };
      })
    );
  }

  getClasses(syllabusId: number) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariablesAndQueryParams(
        'classes',
        [],
        (qs: QueryStringParameters) => {
          qs.push('syllabusId', syllabusId);
        }
      );

    return this.apiService.getX<ClassListItem[]>(endpoint).pipe(
      map(({ error, loading, data }) => {
        if (!data) return { error, loading, data: null };
        if (data) return { error, loading, data };
      })
    );
  }
  getSubjects(classId: number) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariablesAndQueryParams(
        'subjects',
        [],
        (qs: QueryStringParameters) => {
          qs.push('classId', classId);
        }
      );

    return this.apiService.getX<SubListItem[]>(endpoint).pipe(
      map(({ error, loading, data }) => {
        if (!data) return { error, loading, data: null };
        if (data) return { error, loading, data };
      })
    );
  }

  getReactions(courseContentId: number) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariablesAndQueryParams(
        'reactions',
        [],
        (qs: QueryStringParameters) => {
          qs.push('courseContentId', courseContentId);
        }
      );

    return this.apiService.getX<ReactionStat>(endpoint).pipe(
      map(({ error, loading, data }) => {
        if (!data) return { error, loading, data: null };
        if (data) return { error, loading, data };
      })
    );
  }

  getSubGroups(courseId: number, subjectId: number) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'courses',
      [`${courseId}`, 'subjects', `${subjectId}`, 'subgroups']
    );

    return this.apiService.getX<SubGroupsResponse>(endpoint).pipe(
      map(({ error, loading, data }) => {
        if (!data) return { error, loading, data: null };
        if (data) return { error, loading, data };
      })
    );
  }

  getInsightsStatus(courseId, subjectId, date) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariablesAndQueryParams(
        'courses',
        [`${courseId}`, 'subjects', `${subjectId}`, 'insights', 'read'],
        (qs: QueryStringParameters) => {
          qs.push('date', `${date}`);
        }
      );
    return this.apiService.getX<any>(endpoint).pipe(map((res) => res));
  }

  puInsightsStatus(courseId, subjectId, date) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariablesAndQueryParams(
        'courses',
        [`${courseId}`, 'subjects', `${subjectId}`, 'insights', 'read'],
        (qs: QueryStringParameters) => {
          qs.push('date', `${date}`);
        }
      );
    return this.apiService.put<any>(endpoint, {}).pipe(map((res) => res));
  }

  getPermission() {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'contents',
      ['permissions']
    );

    return this.apiService.getX<any>(endpoint).pipe(map((res) => res));
  }

  joinClasswithCode(classCode: string) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      `courses`,
      ['classcode', `${classCode}`, 'enroll']
    );

    return this.apiService.post<any>(endpoint, {}).pipe(map((res) => res));
  }

  addDemo() {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      `contents`,
      ['demo']
    );

    return this.apiService.post<any>(endpoint, {}).pipe(map((res) => res));
  }

  createCourse(classId: number, sectionId: number, year: number) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariablesAndQueryParams(
        'courses',
        [],
        (qs: QueryStringParameters) => {
          qs.push('classId', `${classId}`);
          qs.push('sectionId', `${sectionId}`);
          qs.push('year', `${year}`);
        }
      );

    return this.apiService.postX<courseResponse>(endpoint).pipe(
      map(({ error, loading, data }) => {
        if (!data) return { error, loading, data: null };
        if (data) return { error, loading, data };
      })
    );
  }

  createSubject(payload) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariables(`subjects`);
    return this.apiService.post<any>(endpoint, payload).pipe(map((res) => res));
  }

  createSubGroup(payload) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariables(`subgroups`);
    return this.apiService.post<any>(endpoint, payload).pipe(map((res) => res));
  }

  deleteSubGroup(subGroupId: number) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'subgroups',
      [`${subGroupId}`]
    );
    return this.apiService.delete(endpoint).pipe(map((res) => res));
  }

  addChapter(payload) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariables(`chapters`);
    return this.apiService.post<any>(endpoint, payload).pipe(map((res) => res));
  }

  editSubject(payload) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariables(`subjects`);
    return this.apiService.put<any>(endpoint, payload).pipe(map((res) => res));
  }

  editChapterName(payload) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariables(`chapters`);
    return this.apiService.put<any>(endpoint, payload).pipe(map((res) => res));
  }

  getRefferalCode() {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'users',
      ['referrals']
    );

    return this.apiService.getX<any>(endpoint).pipe(map((res) => res));
  }

  getUsersByRoll(roll, unEnrolledOnly) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariablesAndQueryParams(
        'users',
        [],
        (qs: QueryStringParameters) => {
          qs.push('roleId', `${roll}`);
          qs.push('unEnrolledOnly', `${unEnrolledOnly}`);
        }
      );

    return this.apiService.getX<any>(endpoint).pipe(map((res) => res));
  }

  enrollTeacher(courseId: number, subjectId: number) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      `courses`,
      [`${courseId}`, 'subjects', `${subjectId}`, 'enroll']
    );
    return this.apiService.post<any>(endpoint, {}).pipe(map((res) => res));
  }

  getClassCode(courseId: number, subjectId: number) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'courses',
      [`${courseId}`, 'subjects', `${subjectId}`, 'classcode']
    );

    return this.apiService
      .getX<classCodeResponse>(endpoint)
      .pipe(map((res) => res));
  }

  checkDocStatus(documentId: number) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'contents',
      ['documents', `${documentId}`, 'status']
    );

    return this.apiService.getX<any>(endpoint).pipe(map((res) => res));
  }

  reportQuestion(questionId: string, comments: string) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariablesAndQueryParams(
        'contents',
        ['questions', `${questionId}`, 'report'],
        (qs: QueryStringParameters) => {
          qs.push('comments', `${comments}`);
        }
      );
    return this.apiService.postX<any>(endpoint).pipe(map((res) => res));
  }

  postRestrictions(payload: any) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      `contents`,
      [`${payload.courseContentId}`, 'restrict']
    );
    return this.apiService.post<any>(endpoint, payload).pipe(map((res) => res));
  }

  getUserSubjectsByUserIdEndpoint() {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariablesAndQueryParams(
        `enrollments`,
        [],
        (qs: QueryStringParameters) => {
          qs.push(
            'date',
            `${this.datepipe.transform(new Date(), 'yyyy-MM-dd')}`
          );
        }
      );

    return this.apiService
      .get<DashboardContent>(endpoint)
      .pipe(map((res) => res))
      .toPromise();
  }

  getEnrollmentsByCourse(courseId: number, subjectId: number) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'courses',
      [`${courseId}`, 'subjects', `${subjectId}`, 'enrollments']
    );

    return this.apiService
      .getX<CourseEnrollments>(endpoint)
      .pipe(map((res) => res))
      .toPromise();
  }

  getRestrictions(courseContentId: number) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'contents',
      [`${courseContentId}`, 'restrict']
    );

    return this.apiService.getX<any>(endpoint).pipe(map((res) => res));
  }

  getEnrollmentsByCourseObs(courseId: number, subjectId?: number) {
    const endpoint =
      subjectId != null
        ? this.apiEndpointService.createUrlWithPathVariables('courses', [
            `${courseId}`,
            'subjects',
            `${subjectId}`,
            'enrollments',
          ])
        : this.apiEndpointService.createUrlWithPathVariables('courses', [
            `${courseId}`,
            'enrollments',
          ]);

    return this.apiService.getX<CourseEnrollment[]>(endpoint).pipe(
      map(({ error, loading, data }) => ({
        error,
        loading,
        data: data || null,
      }))
    );
  }

  studentsBulkEnroll(courseId: number, subjectId: number, userIds: number[]) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'courses',
      [`${courseId}`, 'subjects', `${subjectId}`, 'bulk-enroll']
    );
    return this.apiService.postX<any>(endpoint, userIds).pipe(
      map(({ error, loading, data }) => {
        if (!data) return { error, loading, data: null };
        if (data) return { error, loading, data: data };
      })
    );
  }

  getCourseByValue(value: number | string) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'courses',
      [`${value}`]
    );
    return this.apiService
      .get<ClassSectionName[]>(endpoint)
      .pipe(map((res) => res));
  }

  getChapterListByCourseAndSubject(courseId: number, subjectId: number) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'courses',
      [`${courseId}`, 'subjects', `${subjectId}`]
    );
    return this.apiService
      .get<ChapterContent>(endpoint)
      .pipe(map((res) => res));
  }

  getBookMarkedChapter(courseId: number, subjectId: number) {
    return this.getChapterListByCourseAndSubject(courseId, subjectId).pipe(
      tap((data) => this.dashboardService.setCurrentChapterContent(data)),
      map(
        (res) =>
          res.chapterViewList.filter((item) => item.bookmarked)[0] ||
          (res.chapterViewList.filter((item) => !item.generalContent).length > 0
            ? res.chapterViewList.filter((item) => !item.generalContent)[0]
            : res.chapterViewList.filter((item) => item.generalContent)[0])
      )
    );
  }

  getChapterContentByCourseAndChapterEndpoint(
    courseId: number,
    chapterId: number,
    textonlyStatus: boolean
  ) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariablesAndQueryParams(
        'courses',
        [`${courseId}`, 'chapters', `${chapterId}`],
        (qs: QueryStringParameters) => {
          qs.push('textonly', textonlyStatus);
        }
      );

    return this.apiService
      .get<ChapterView>(endpoint)
      .pipe(map((res) => res))
      .toPromise();
  }
  getChapterContentByCourseAndChapter(courseId: number, chapterId: number) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'courses',
      [`${courseId}`, 'chapters', `${chapterId}`]
    );

    return this.apiService.get<ChapterView>(endpoint).pipe(map((res) => res));
  }

  createFlashcardQuestion(question: Question, editStatus: boolean) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'contents',
      ['questions']
    );
    question = { ...question, subjective: false };
    let request = editStatus
      ? this.apiService.putX<Question>(endpoint, question)
      : this.apiService.postX<Question>(endpoint, question);
    return request.pipe(
      map(({ error, loading, data }) => {
        if (!data) return { error, loading, data: null };
        if (data) return { error, loading, data: data };
      })
    );
  }
  createNotesQuestion(question: Question, editStatus: boolean) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'contents',
      ['questions']
    );
    question = { ...question, subjective: false };
    let request = editStatus
      ? this.apiService.putX<Question>(endpoint, question)
      : this.apiService.postX<Question>(endpoint, question);
    return request.pipe(
      map(({ error, loading, data }) => {
        if (!data) return { error, loading, data: null };
        if (data) return { error, loading, data: data };
      })
    );
  }

  getVideoContent(contentId: number) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'contents',
      ['interactivevideos', `${contentId}`]
    );

    return this.apiService.get<VideoContent>(endpoint).pipe(map((res) => res));
  }

  getCourseSubGroups(courseId: number, subjectId: number) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'courses',
      [`${courseId}`, 'subjects', `${subjectId}`, 'subgroups']
    );

    return this.apiService.get<any>(endpoint).pipe(map((res) => res));
  }

  getCourseDetails(classId: number, sectionId: number, year: number) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariablesAndQueryParams(
        'courses',
        [],
        (qs: QueryStringParameters) => {
          qs.push('classId', `${classId}`);
          qs.push('sectionId', `${sectionId}`);
          qs.push('year', `${year}`);
        }
      );

    return this.apiService.get<any>(endpoint).pipe(map((res) => res));
  }

  getOrgDetails(organisationId: number) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'organisations',
      [`${organisationId}`]
    );

    return this.apiService.get<any>(endpoint).pipe(map((res) => res));
  }

  /** need to check with aravind  */
  getQuestionsByChapterAndOrganisationEndpoint(
    chapterId: number,
    contentTypeId: number
  ) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariablesAndQueryParams(
        'courses',
        ['chapter', `${chapterId}`, 'questions'],
        (qs: QueryStringParameters) => {
          qs.push('textonly', false);
          qs.push('questionContentTypeIds', contentTypeId);
        }
      );

    return this.apiService.get<QuestionContent[]>(endpoint).pipe(
      map((res) => res),
      map((res) =>
        res.map((content) => ({
          ...content,
          questionText: content.questionText,
          answers: content.answers,
          checked: false,
          showAnswers: false,
          selected: false,
        }))
      )
    );
  }

  duplicateQuestions(questions: number[], chapterId: number) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariablesAndQueryParams(
        'contents',
        ['questions', 'copy'],
        (qs: QueryStringParameters) => {
          qs.push('chapterId', `${chapterId}`);
        }
      );
    return this.apiService
      .postX<any>(endpoint, questions)
      .pipe(map((res) => res));
  }

  questionBankDelete(questionId: number) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'contents',
      ['questions', `${questionId}`]
    );
    return this.apiService.delete(endpoint).pipe(map((res) => res));
  }

  toggleChapterCompletion(
    orgId: number,
    courseId: number,
    subjectId: number,
    chapterId: number,
    complete: boolean
  ) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariablesAndQueryParams(
        'courses',
        [
          `${courseId}`,
          'subjects',
          `${subjectId}`,
          'chapters',
          `${chapterId}`,
          'complete',
        ],
        (qs: QueryStringParameters) => {
          qs.push('complete', `${complete}`);
        }
      );
    return this.apiService.post<any>(endpoint, {}).pipe(map((res) => res));
  }

  unenrollUser(courseId: number, subjectId: number, userId: number) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariablesAndQueryParams(
        'courses',
        [`${courseId}`, 'subjects', `${subjectId}`, 'unenroll'],
        (qs: QueryStringParameters) => {
          qs.push('userId', `${userId}`);
        }
      );
    return this.apiService.post<any>(endpoint, {}).pipe(map((res) => res));
  }

  createQuestion(question: Question, type: string) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'contents',
      ['questions']
    );

    // if (!('flashCard' in question)) {
    //   question = { ...question, flashCard: false };
    // }
    // if (!('subjective' in question)) {
    //   question = { ...question, subjective: false };
    // }
    let request =
      type === 'update'
        ? this.apiService.putX<Question>(endpoint, question)
        : this.apiService.postX<Question>(endpoint, question);

    return request.pipe(
      map(({ error, loading, data }) => {
        if (!data) return { error, loading, data: null };
        if (data) return { error, loading, data };
      })
    );
  }

  //question paper apis

  getQuestionPaperList(courseId: number, subjectId: number, chapterId: number) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'contents',
      [
        'courses',
        `${courseId}`,
        'subjects',
        `${subjectId}`,
        'chapters',
        `${chapterId}`,
        'questionpapers',
      ]
    );

    return this.apiService
      .get<QuestionPaper[]>(endpoint)
      .pipe(map((res) => res));
  }

  createQuestionPaper(payload: QuestionPaperReq, isEditQuestionPaper: boolean) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'contents',
      ['questionpapers']
    );

    let request = isEditQuestionPaper
      ? this.apiService.putX<Question>(endpoint, payload)
      : this.apiService.postX<Question>(endpoint, payload);

    return request.pipe(
      map(({ error, loading, data }) => {
        if (!data) return { error, loading, data: null };
        if (data) return { error, loading, data };
      })
    );
  }

  doGetOrDeleteQuestionPaperById(questionPaperId: number, type: string) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'contents',
      ['questionpapers', `${questionPaperId}`]
    );

    let request;

    if (type === 'GET') {
      request = this.apiService.getX<QuestionPaper>(endpoint);
    } else if (type === 'DELETE') {
      request = this.apiService.deleteX<Question>(endpoint);
    }

    if (!request) {
      return;
    }

    return request.pipe(
      map(({ error, loading, data }) => {
        if (!data) return { error, loading, data: null };
        if (data) return { error, loading, data };
      })
    );
  }

  startSession(payload) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariables('resourcesession');
    return this.apiService.postX<any>(endpoint, payload).pipe(
      map(({ error, loading, data }) => {
        if (!data) return { error, loading, data: null };
        if (data) return { error, loading, data };
      })
    );
  }

  getLessonPlans(
    chapterId: number,
    courseId: number,
    subjectId: number,
    orgId: number
  ) {
    let endpoint = '';
    if (orgId === 1) {
      endpoint =
        this.apiEndpointService.createUrlWithPathVariablesAndQueryParams(
          'lessonplans',
          [],
          (qs: QueryStringParameters) => {
            qs.push('chapterId', `${chapterId}`);
          }
        );
    } else {
      endpoint =
        this.apiEndpointService.createUrlWithPathVariablesAndQueryParams(
          'lessonplans',
          [],
          (qs: QueryStringParameters) => {
            qs.push('chapterId', `${chapterId}`);
            qs.push('courseId', `${courseId}`);
            qs.push('subjectId', `${subjectId}`);
          }
        );
    }

    return this.apiService.get<LessonPlan[]>(endpoint).pipe(map((res) => res));
  }

  getAttendanceByOrganisationAndCourse(
    courseId: number,
    subjectId: number,
    startDate: string,
    endDate: string
  ) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariablesAndQueryParams(
        'attendances',
        [],
        (qs: QueryStringParameters) => {
          qs.push('courseId', `${courseId}`);
          qs.push('subjectId', `${subjectId}`);
          qs.push('from', `${startDate}`);
          qs.push('to', `${endDate}`);
        }
      );

    return this.apiService
      .get<AttendanceContent>(endpoint)
      .pipe(map((res) => res));
  }

  checkIsAssessmentExistByContentTypeId(id: number) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'contents',
      ['courseContentId', 'insights', 'validateAssessment', `${id}`]
    );

    return this.apiService.get<any>(endpoint).pipe(map((res) => res));
  }

  //announcements api
  getAnnouncements(courseId: number, subjectId: number) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'courses',
      [`${courseId}`, 'subjects', `${subjectId}`, 'announcements']
    );

    return this.apiService.get<Announcment[]>(endpoint).pipe(map((res) => res));
  }

  deleteAnnouncmentById(announcememntId: number) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'announcements',
      [`${announcememntId}`]
    );

    return this.apiService
      .delete<Announcment>(endpoint)
      .pipe(map((res) => res));
  }

  createAnnouncement(
    courseId: number,
    subjectId: number,
    announcementText: string,
    announcementId: number,
    isEdit: boolean
  ) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariables('announcements');
    let obj: Announcment = { courseId, subjectId, announcementText };
    if (isEdit) {
      obj = { ...obj, announcementId };
    }
    let method = isEdit ? 'put' : 'post';
    let request = this.apiService[method]<Announcment>(endpoint, obj);
    return request.pipe(map((res) => res));
  }
}

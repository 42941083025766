import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material/material.module';
import { MinutePipe } from './pipes/minute.pipe';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { SafePipe } from './pipes/safe.pipe';
import { CustomSpinnerDirective } from './directives/custom-spinner.directive';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { UserRoleDirective } from './directives/user-role.directive';
import { CountdownPipe } from './pipes/countdown.pipe';
import { CountdownSecsPipe } from './pipes/countdownsecs.pipe';
import { ArraySortPipe } from './pipes/array-sort.pipe';
import { RenderMathModule } from './components/render-math/render-math.module';
import { HtmlEditorModule } from '@libs/html-editor/html-editor.module';
import { MatrixEffectComponent } from './components/matrix-effect/matrix-effect.component';
import { NumberToLetterPipe } from './pipes/number-to-letter.pipe';
import { CustomSelectorComponent } from './components/custom-selector/custom-selector.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
/**TODO:
 * Instead of a single shared module, split the shared components into multiple modules
 */

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MaterialModule,
    HtmlEditorModule,
    RenderMathModule,
  ],
  declarations: [
    MinutePipe,
    ConfirmDialogComponent,
    SafePipe,
    CustomSpinnerDirective,
    AlertDialogComponent,
    UserRoleDirective,
    CountdownPipe,
    CountdownSecsPipe,
    ArraySortPipe,
    MatrixEffectComponent,
    NumberToLetterPipe,
    CustomSelectorComponent,
  ],
  exports: [
    MaterialModule,
    MinutePipe,
    RenderMathModule,
    CountdownPipe,
    HtmlEditorModule,
    CountdownSecsPipe,
    ConfirmDialogComponent,
    SafePipe,
    ArraySortPipe,
    NumberToLetterPipe,
    CustomSpinnerDirective,
    UserRoleDirective,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatMomentModule,
    MatrixEffectComponent,
    CustomSelectorComponent,
  ],
})
export class SharedModule {}
